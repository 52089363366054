.eventManager {
    display: flex;
    justify-content: space-between;
    section.formsSection {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        margin: 2px;
        section.eventSection {
            border: 1px solid;
            padding: 20px;
            .sectionHead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .sectionTitle {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .sectionToggleButton {
                        background-color: unset;
                        border: none;
                        color: black;
                        box-shadow: none;
                        &:hover {
                            background-color: unset;
                            border: none;
                        }
                    }
                }
                .sectionIcon {
                    font-size: 18px;
                    color: #ccc;
                    &.complete {
                        color: green;
                    }
                    &.error {
                        color: red;
                    }
                }
            }
            .sectionContent {
                height: 0;
                overflow: hidden;
            }
            &.open {
                .sectionContent {
                    height: unset;
                }
            }
        }
    }
    section.summarySection {
        min-width: 180px;
        display: flex;
        flex-flow: column nowrap;
        height: 400px;
        border: 1px solid black;
        background-color: $WHITE;
        margin: 2px;
        padding: 10px;
        justify-content: space-between;
        h4 {
            margin: 0;
        }
        ol {
            text-align: right;
            padding: 0;
            list-style: none;
            li {
                &:last-child {
                    border-top: 1px solid;
                    font-weight: bold;
                }
            }
        }
    }
}

.personal-info, .registration-info {
    display: flex;
    flex-flow: column;
    input[type=text] {
        width: unset;
    }
}

.toggleButtons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.toggleButton {
    border-radius: 0;
    padding: 10px;
    border: 1px solid #cbd6e2;
    background-color: #f5f8fa;
    color: #444;
    margin: 5px;
    display: flex;
    align-items: center;
    &:hover {
        box-shadow: 0 0 5px #51cbee;
        border: 1px solid #51cbee;
        background-color: #f5f8fa;
    }
    .icon {
        padding-left: 10px;
        font-size: 18px;
        color: #ccc;
    }
    &.on {
        font-weight: bold;
        .icon {
            color: green;
        }
    }
}

.completeSection {
    background-color: #eee;
    padding: 10px;
    border-radius: 5px;
    .completeIcon {
        font-size: 24px;
        display: flex;
        justify-content: center;
        &.done {
            color: green;
        }
    }
}

@media (max-width:768px) {
    .eventManager {
        section.summarySection {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 200px;
        }
    }
}