@import 'style/_base';

.media-image {
    height:400px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    margin-right: 10px;
    .delete-button {
        position: relative;
        top: 350px;
        text-align:center;
    }
}
.media-noimage {
    height:400px;
    border: 1px solid $MIDGRAY;
    text-align:center;
    display:flex;
    flex-flow: column;
    justify-content: center;
    margin-right: 10px;
}
.thumbnail-images {
    display: flex;
    flex-flow: row;
    margin-top: 10px;
    .thumbnail-image {
        width: 50px;
        height: 50px;
        background-repeat:no-repeat;
        background-position:50% 50%;
        background-size: contain;
        border: 1px solid $MIDGRAY;
        cursor: pointer;
        margin-left: 5px;
        &:first-child {
            margin-left: 0;
        }
        &.selected {
            border: 1px solid $CORAL1;
            cursor: default;
        }
    }
}

@media (max-width:768px) {
    .media-noimage {
        margin: 0;
    }
}

.image-viewer {
    display: flex;
    border: 1px solid $DARKGRAY;
    overflow-x: auto;
    height: 370px;
    .image-view-container {
        width: 300px;
        height: 300px;
        border: 1px solid $MIDGRAY;
        margin: 10px;
        .image-view-image {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 300px;
            height: 300px;
            margin-bottom: 5px;
        }
        .image-view-buttons {
            display: flex;
            justify-content: center;
        }
    }
}