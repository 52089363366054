@import 'style/_base';

body {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    #root {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .outer-root-container {
            position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        }
    }
}

.login-container {
	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
    height: 100%;
    justify-content: space-between;
	font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	.login-center-container {
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        justify-content: center;
        .login-center-pane-outer {
            width: 650px;
            .login-center-pane-inner {
                display: flex;
                flex-flow: column;
                border: 1px solid $MIDGRAY;
                border-radius: 5px;
                box-shadow: 0 3px 6px #00000029;
                margin: 50px;
                padding: 30px 50px;
                h1 {
                    margin: 0;
                    font-weight: normal;
                }
            }
        }
    }
	.form-container {
		display: flex;
		flex-flow: column nowrap;
		margin-top: 20px;
		padding-bottom: 100px;
		.password-container {
			display: none;
			margin-top: 20px;
		}
		.next-button-container {
			margin-top:50px;
		}
		.mfa-setup-container {
			display: none;
			flex-flow: column nowrap;
		}
		.mfa-code-container {
			display: none;
			flex-flow: column nowrap;
		}
	}
	input {
		width: 100%;
	}
}
.password-stuff {
	font-size:18px;
    width:100%;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    input[type=checkbox] {
        width: unset;
    }
}
.input-error {
	color: red;
	font-size: smaller;
}

@media (max-width:768px) {
    .login-container {
        .login-center-container {
            .login-center-pane-outer {
                width: 100%;
                .login-center-pane-inner {
                    border: none;
                    box-shadow: none;
                    margin: 14px;
                    padding: 0;
                    input[type=email],input[type=password] {
                        padding: 7px 0;
                    }
                }
            }
        }
        .payment-form {
            width: 100%;
            padding: 0;
        }
    }
}