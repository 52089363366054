@import './base';

.mobile-view {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    &.within-portal {
        top: 30px;
        justify-content: space-between;
        bottom: 0;
        overflow: auto;
    }
    .view-container {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        padding: 15px;
        overflow-y: auto;
        &.schedule {
            ul.schedule-day {
                list-style: none;
                margin: 0;
                padding: 0;
                border: 1px solid $MIDGRAY;
                .day-title {
                    font-weight:600;
                    padding: 5px;
                    background-color: $DARKGRAY;
                    color: white;
                    text-align: center;
                }
                ul.schedule-data {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        border: 1px solid $MIDGRAY;
                        padding: 10px;
                    }
                }
            }
        }
        &.maps {

        }
        &.vendors {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    margin: 2px 0;
                }
            }
        }
    }
    .navigation-bar {
        display: flex;
        justify-content: space-around;
        .navigation-button {
            border: 1px solid $MIDGRAY;
            display: flex;
            width: 100%;
            padding: 10px 0;
            justify-content: center;
            &.selected {
                background-color: $LIGHTGRAY;
                
            }
            &:hover {
                background-color: $MIDGRAY;
                color: white;
            }
        }
    }
}