@import 'style/_base';

.payment-method-info {
    display: flex;
    flex-flow: column nowrap;
    /*width: 80%;*/
    .payment-method-error {
        color: red;
    }
    .hint {
        color: #636363;
        font-size: smaller;
        padding-left: 5px;
    }
}