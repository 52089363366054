@import 'style/_base';

 ul.ticket-picker {
    list-style: none;
    padding: 0;
    li {
        display: flex;
        flex-flow: column nowrap;
        border-bottom: 1px solid;
        padding: 10px 0;
        .ticket-details {
            display: flex;
            justify-content: space-between;
            .ticket-info {
                display: flex;
                align-items: center;
                input[type=number] {
                    width: 50px;
                    margin-right: 10px;
                }
                .ticket-image {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100px;
                    height: 100px;
                    border: 1px solid;
                    margin: 0 4px;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                }
            }
            .ticket-values {
                display: flex;
                flex-flow: column nowrap;
                .ticket-value {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    .ticket-value-label {
                        display: flex;
                        white-space: nowrap;
                        margin-right: 10px;
                        align-items: center;
                        .sub-label {
                            font-size: smaller;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .ticket-info-container {
            display: none;

        }
    }
}
