@import 'style/_base';

.roster {
    margin: 10px;
    table {
        border-collapse: collapse;
        border: 1px solid gray;
        width: 100%;
        thead tr {
            background-color: silver;
        }
        tbody tr {
            border-bottom: 1px solid gray;
            td {
                text-align:center;
            }
        }
    }
}