@import 'style/_base';

.copyright-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    height: 50px;
    padding: 20px;
    background: $LIGHTGRAY;
    width: 100%;
    .policiesContainer a {
        margin-right: 15px;
    }
}