@import 'style/_base';

.activity {
    border-bottom: 1px solid $MIDGRAY;
    h1 {
        color: $CORAL1;
    }
    h3 {
        margin-bottom: 0;
    }
    .day {
        font-weight: bold;
    }
    .time {
        font-size: small;
    }
    .title {
        font-weight: bold;
    }
    .description {
        font-size: smaller;
    }
}