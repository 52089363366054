@import 'style/_base';

.payment-history {
    display: flex;
    flex-flow: column nowrap;
    max-height: 100px;
    overflow: auto;
    .history-item {
        display: flex;
        justify-content: space-between;
        &.header {
            font-weight: bold;
            border-bottom: 1px solid $MIDGRAY;
        }
    }
}