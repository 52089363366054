@import 'style/_base';

.foundEntries {
    margin-top: 50px;
    table {
        width: 100%;
        tr td {
            text-align: center;
        }
    }
}