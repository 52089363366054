@import 'style/_base';

.voter-ballot {
    display: flex;
    flex-flow: column nowrap;
    padding: 50px;
    .ballot-form {
        display: flex;
        flex-flow: column nowrap;
        padding: 50px 0;
        max-width: 400px;
    }
}