@import 'style/_base';

.social-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
}

.post-tiles {
    display: flex;
    flex-flow: column nowrap;
    //width: 574px;
    margin: auto;
    .post-tile {
        display: flex;
        flex-flow: column nowrap;
        border: 1px solid $MIDGRAY;
        margin: 5px;
        padding: 5px;
        max-width: 552px;
        
        .post-header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            font-size: 14px;
            border-bottom: 1px solid $MIDGRAY;
            .post-author-info {
                display: flex;
                flex-flow: row nowrap;
                .post-info {
                    padding-left: 4px;
                    .post-author {
                        font-weight: bold;
                    }
                    .post-date {
                        color: $DARKGRAY;
                    }
                }
            }
            .post-owner-options {
                display: flex;
                flex-flow: row;
                .owner-option {
                    color: $DARKGRAY;
                    font-size: 20px;
                    padding: 0 5px;
                    cursor: pointer;
                    &:hover {
                        color: $CORAL1;
                    }
                }
            }
        }
        .post-image {
            width: 550px;
            height: 550px;

            background-repeat:no-repeat;
            background-position:50% 50%;
            background-size: contain;
            border: 1px solid $MIDGRAY;
        }
        .post-message {
            font-size: 16px;
            max-height: 200px;
            text-overflow: ellipsis;
            overflow: auto;
            padding: 10px;
        }
        .post-activity {
            border-top: 1px solid $MIDGRAY;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            font-size:14px;
            color: $DARKGRAY;
            padding: 5px 0;
            .post-likes:hover {
                color: $CORAL1;
                cursor: pointer;
            }
            .post-comments:hover {
                color: $CORAL1;
                cursor: pointer;
            }
        }
        .post-actions { 
            display: flex;
            flex-flow: row nowrap;
            font-size: 16px;
            color: $DARKGRAY;
            justify-content: space-around;
            border-top: 1px solid $MIDGRAY;
            padding: 5px 0;
            position: relative;
            .action {
                text-align: center;
                &:hover {
                    color: $CORAL1;
                    cursor: pointer;
                }
            }
            .reactions {
                display: flex;
                flex-flow: row nowrap;
                position: absolute;
                top: -44px;
                left: 15px;
                background-color: white;
                border: 1px solid $MIDGRAY;
                border-radius: 15px;
                .reaction-buttons {
                    display: flex;
                    flex-flow: row nowrap;
                    padding: 5px;
                    font-size: 24px;
                    .rbtn {
                        cursor: pointer;
                        padding: 0 2px;
                        &:hover {
                            color: $CORAL1;
                        }
                    }
                }
            }
        }
        .post-comments-list {
            display: flex;
            flex-flow: column nowrap;
            border-top: 1px solid $MIDGRAY;
            .post-comment {
                display: flex;
                flex-flow: column nowrap;
                align-self: flex-start;
                font-size: 16px;
                background-color: $BLUE2;
                border: 1px solid $BLUE2;
                padding: 5px 10px;
                margin: 5px;
                border-radius: 10px;
                .comment-info {
                    font-size: 14px;
                }
                .comment-message {

                }
            }
        }
    }
}
.get-more-posts {
    display: flex;
    flex-flow: column nowrap;
    width: 574px;
    margin: auto;
    button {
        height: 50px;
    }
}

.album {
    display: flex;
    flex-flow: column nowrap;
    border: 1px solid $MIDGRAY;
    margin: 5px;
    max-height: 924px;
    .album-title {
        border-bottom: 1px solid $MIDGRAY;
        text-align:center;
    }
    .media-viewer {
        display: none;
    }
    .media-list-container {
        max-height: 1000px;
        width: 332px;
        overflow: auto;
        display: flex;
        flex-flow: row;
        ul {
            list-style: none;
            padding: 0 10px;
            li {
                float: left;
                padding: 2px;
                cursor: pointer;
                .thumbnail-image {
                    width: 100px;
                    height: 100px;
                    background-repeat:no-repeat;
                    background-position:50% 50%;
                    background-size: contain;
                }
            }
        }
    }
    &.full {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        background: white;
        z-index: 100;
        .media-list-container {
            position: absolute;
            bottom: 0;
            width: 100%;
            border-top: 1px solid $MIDGRAY;
        }
        .media-viewer {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            .media-viewer-header {
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid $MIDGRAY;
                padding: 10px 0;
                width: 100%;
                justify-content: flex-end;
            }
            .media-viewer-container {
                width: 100%;
                height: 500px;
                background-repeat:no-repeat;
                background-position:50% 50%;
                background-size: contain;
            }
        }
    }
}

@media (max-width:768px) {
    .post-tiles {
        .post-tile {
            padding: 2px;
            margin: 5px 0;
        }
    }
    .album {
        display: none;
    }
}