@import 'style/_base';

.child-logins {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    .child-login {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        &.header {
            font-weight:bold;
            border-bottom: 1px solid $MIDGRAY;
        }
        div {
            margin: 2px 0;
            img {
                height:20px;
            }
        }
    }
}