@import 'style/_base';

.calTitleBar {
    width: 75%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px;
    height: 25px;
    div {
        width: 10%;
        float: left;
        border-radius: 5px;
        text-align:center;
        height: 24px;
        padding-top: 5px;
        &:last-child {
            float: right;
        }
    }
    .calTitle {
        width: 80%;
        text-align:center;
    }
}
table.scheduledMesssagesTable {
    border-top: 1px solid $MIDGRAY;
    width: 75%;
    /*height: 400px;*/
    margin: auto;
    tr {
        &.weekDay {
            color: $DARKGRAY;
        }
        td {
            text-align:center;
            font-size: 20px;
            .dayLabel {
                cursor: pointer;
                height: 25px;
                width: 30px;
                margin: auto;
                font-size: 16px;
                padding-top: 6px;
                &.today {
                    border: 1px solid $NAVY1;
                    border-radius: 15px;
                }
            }
            .activities {
                height: 15px;
                font-size: 10px;
                color: white;
                &.on {
                    color: $NAVY1;
                }
            }
        }
    }
}