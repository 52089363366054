@import 'style/_base';

.payment-methods {
    display: flex;
    flex-flow: column nowrap;
    .payment-method {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        &.header {
            font-weight:bold;
            border-bottom: 1px solid $MIDGRAY;
        }
        div {
            margin: 2px 0;
            img {
                height:20px;
            }
        }
    }
}