@import './base';

.member-rides {
    .no-rides {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 24px;
    }
    .member-ride {
        border: 1px solid $MIDGRAY;
        margin: 10px;
        padding: 10px;
        .ride-header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $MIDGRAY;
            padding-bottom: 10px;
            align-items: center;
            .ride-title {
                font-weight: 600;
                font-size: 24px;
            }
            .ride-button {
                color: $DARKGRAY;
                font-size: 24px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .ride-center {
            display: flex;
            border-bottom: 1px solid $MIDGRAY;
            padding: 10px 0;
            max-height: 200px;
            overflow: auto;
            .ride-description {
                font-size: 18px;
                white-space: pre-wrap;
            }
        }
        .ride-images {
            display: flex;
            flex-flow: row nowrap;
            overflow-x: auto;
            .ride-image {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 400px;
                height: 400px;
                flex: 0 0 auto;
                margin-right: 10px;
            }
        }
    }
    .more-button-container {
        display: flex;
        justify-content: center;
    }
}