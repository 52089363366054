@import 'style/_base';
.event-container {
	display: flex;
	flex-flow: column nowrap;
	min-width: 400px;
    max-width: 800px;
	margin: 50px auto 100px;
	font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
	input[type=text] {
		width: 100%;
	}
    .event-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            border: 1px solid $MIDGRAY;
            padding: 5px;
            text-align:center;
            margin: 5px 0;
            cursor: pointer;
            background-color: $LIGHTGRAY;
            .event-details {
                display: flex;
                .event-image {
                    width: 150px;
                    height: 150px;
                    background-repeat:no-repeat;
                    background-position:50% 50%;
                    background-size: contain;
                    //border: 1px solid $MIDGRAY;
                }
                .event-description {
                    display: flex;
                    text-align: left;
                    white-space: pre-wrap;
                }
            }
            .event-dates {
                font-size: 12px;
                padding: 10px;
            }
            
            &:hover {
               border: 1px solid $CORAL2; 
            }
        }
    }
}

.copyright-outer-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: white;
    border-top: 1px solid $MIDGRAY;
    position: fixed;
    bottom: 0;
    width:100%;
    height: 80px;
}