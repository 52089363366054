@import 'style/_base';

.create-classified {
    display: flex;
    flex-flow: column nowrap;
    margin: 20px;
    .back-button-container {
        display: flex;
        font-size: 24px;
    }
    .instructions-area {
        display:flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 8px 0;
    }
    .classified-media {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
    }
    .classified-details {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        //width: 50%;
        padding: 0 30px 0 0;
        textarea {
            height: 200px;
        }
    }
    .classified-save-button {
        margin-top: 20px;
        display: flex;
    }
}
.classifieds {
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;

    .classified-item-list {
        width: 90%;
        margin: auto;
        cursor: pointer;
        .classified-item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            border-bottom: 1px solid $MIDGRAY;
        }
    }
}