
// Primary Palette
$CORAL1: #C45025;
$CORAL2: #E7B7A6;
$CORAL3: #F7E7E1;
$NAVY1: #273649;
$NAVY2: #B9BDC3;
$NAVY3: #E2E4E7;
$BLUE1: #2599C4;
$BLUE2: #66B7D5;
$BLUE3: #D3EAF3;
$MUSTARD1: #FFDD4A;
$MUSTARD2: #FFEA92;
$MUSTARD3: #FFF8DA;
// Neutral Palette
$WHITE: #FFFFFF;
$LIGHTGRAY: #F7F7F7;
$MIDGRAY: #C5C5C5;
$DARKGRAY: #626262;
$BLACK: #000000;
// Semantic Palette
$LINK: #0079C1;
$SUCCESS: #21AA3E;
$WARNING1: #FFBE2F;
$WARNING2: #C67917;
$ERROR1: #B00020;
$ERROR2: #8E030F;
// Notifications
$PRIMARY: #CFE2FF;
$SUCCESS: #D1E7DD;
$DANGER: #F8D7DA;
$WARNING: #FEF3CD;

body {
    font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h1 {font-size: 40px;font-weight:600;}
h2 {font-size: 34px;font-weight:500;}
h3 {font-size: 28px;font-weight:400;}
h4 {font-size: 24px;font-weight:300;}
h5 {font-size: 20px;font-weight:200;}

input[type=text], input[type=password], input[type=phone], input[type=email], input[type=number], textarea {
	border: 1px solid $DARKGRAY;
	background-color: $WHITE;
	border-radius: 4px;
	padding: 7px;
	outline: none;
    box-sizing: border-box;
	margin: 0 2px;
	&:focus {
		outline-width: 1px;
        outline-style: dashed;
        outline-color: #7FADDC;
	}
}
input[type=checkbox] {
	border: 2px solid $NAVY2!important;
	border-radius: 0;
    width: 20px;
	background-color: white;
	outline: none;
}
input[type=number] {
    width: 100px;
}
button {
	cursor: pointer;
	color: white;
	background-color: $CORAL1;
	border: 1px solid $CORAL1;
	border-radius: 4px;
	padding: 4px 20px;
	margin: 0 4px;
	outline:none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	&:hover {
		background-color: $NAVY1;
        border-color: $NAVY1;
	}
	&.secondary {
		background-color: white;
		color: $CORAL1;
		&:hover {
			background-color: $NAVY1;
            color: $WHITE;
		}
	}
	&.gray, &:disabled {
		opacity: 50%;
        cursor: default;
	}
	&.icon {
        background-color: unset;
        border: none;
        padding: 2px;
        font-size: 24px;
    }
    &.linkButton {
		background: none;
		border: none;
		border-radius: 0;
		padding: 4px 0;
		color: $LINK;
        box-shadow: none;
        &:focus {
            outline: none;
        }
		&:hover {
			font-weight:bold;
		}
	}
}
select {
	display: block;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid $DARKGRAY;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: 4px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: $WHITE;
	&:hover {
		border-color: #888;
	}
	&.focus {
		outline-width: 1px;
        outline-style: dashed;
        outline-color: #7FADDC;
	}
	option {
		font-weight:normal;
	}
}
hr {
	width: 100%;

}
.button-container {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0;
}
.icon-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    margin: auto 8px;
    height: 42px;
    .icon-button-contents {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        .icon-container {
            font-size: 14px;
        }
        .label-container {
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    &:focus {
        outline-style: dashed;
        outline-width: 2px;
        outline-color: #7FADDC;
    }
}
.password-container {
    width: 90%;
}
.view-password-toggle {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 10%;
    color: $BLUE1;
    &:hover {
        color: $LINK;
    }
    .icon-button {
        height: 30px;
    }
}

.month-picker {
    display: inline-flex;
    flex-flow: column;
    position: relative;
    width: 150px;
    margin: 0 5px;
    .current-value {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        border: 1px solid $DARKGRAY;
        border-radius: 5px;
        width: 100%;
        max-width: 150px;
        height: 28px;
        font-size: 18px;
        cursor: pointer;
    }
    .month-picker-popup {
        display: none;
        flex-flow: column;
        border: 1px solid $DARKGRAY;
        border-radius: 10px;
        font-size: 14px;
        width: 320px;
        background-color: white;
        position: absolute;
        top: 32px;
        z-index: 1000;
        &.show {
            display: flex;
        }
        .year-chooser-container {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $DARKGRAY;
            .year-chooser-controls {
                display: flex;
                flex-flow: row;
                flex: 1;
                justify-content: space-evenly;
                font-weight: 600;
                font-size: 18px;
                .year-chooser-button {
                    cursor: pointer;
                }
                .year-chooser-label {

                }
            }
            .close-popup-button {
                margin-right: 5px;
            }
        }
        .month-chooser-container {
            display: flex;
            flex-flow: column;
            .month-chooser-row {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-evenly;
                align-items: center;
                margin: 5px 5px;
                .month-choice {
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    border: 1px solid $CORAL1;
                    background-color: $CORAL1;
                    color: white;
                    padding: 4px;
                    border-radius: 10px;
                    width: 60px;
                    &:hover {
                        background-color: $CORAL3;
                        color: $DARKGRAY;
                    }
                    &.selected {
                        background-color: $CORAL2;
                        color: $DARKGRAY;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}