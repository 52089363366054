@import '_base';
@import 'react-datepicker/dist/react-datepicker.css';

body {
    /*font: 14px "Century Gothic", Futura, sans-serif;*/
    font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
}

.portal {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    .portal-header {
        display: flex;
        flex-flow: row nowrap;
        background-color: $NAVY1;
        color: white;
        padding: 10px 20px;
        .header-content {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            .content-left {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                font-size: 24px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                
            }
            .content-right {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                .language-switcher {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                }
                .top-bar-divider {
                    width: 1px;
					background-color: $BLACK;
					margin: 10px 5px;
					height: 22px;
                }
                div.button {
                    padding: 6px 10px;
                    cursor: pointer;
                    &:hover {
                        color: $CORAL2;
                    }
                }
                select {
                    padding: 0;
                    width: unset;
                    border: 0;
                    background-color: unset;
                    box-shadow: none;
                    &:focus-visible {
                        outline: none;
                    }
                }
            }
        }
    }
    .portal-body {
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        .body-content {
            display: flex;
            flex-flow: row nowrap;
            width:100%;
            height: 100%;
            .left-side {
                width:100%;
                display:flex;
                flex-flow: column nowrap;
                position: relative;
            }
        }
    }
    .portal-footer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: white;
    }
    .info-container {
        display: flex;
        flex-flow: column nowrap;
        .info-label {
            font-size: 26px;
            font-weight: bold;
            padding: 10px;
        }
        .info-content {
            padding: 10px;
            font-size: 24px;
        }

    }
    .about-container {
        display: flex;
        flex-flow: column nowrap;
        overflow: auto;
        .about-label {
            font-size: 26px;
            font-weight: bold;
            padding: 10px;
        }
        .about-content {
            padding: 10px;
            font-size: 24px;
            white-space: break-spaces;
        }
    }
    .files-container {
        display: flex;
        flex-flow: column;
        overflow: auto;
        .files-label {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            font-size: 26px;
            font-weight: bold;
            padding: 10px;
        }
        .file-item-list {
            overflow: auto;
            .file-item {
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid $MIDGRAY;
                cursor: pointer;
                margin: 5px;
                padding: 5px;
                font-size: 18px;
                .file-icon {
                    padding: 0 10px;
                }
                .file-name {
                    padding: 0 10px;
                }
            }
        }
    }
    .events-container {
        display: flex;
        flex-flow: column nowrap;
        overflow: auto;
        .events-label {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            font-size: 26px;
            font-weight: bold;
            padding: 10px;
            .cart {
                cursor: pointer;
                .cart-count {
                    position:relative;
                    background-color:red;
                    text-align:center;
                    color:white;
                    border-radius: 10px;
                    width: 20px;
                    font-size: 12px;
                    font-weight: normal;
                    top: -20px;
                }
            }
        }
        .event-panels {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            .no-events-message {
                display: flex;
                flex-flow: column nowrap;
                justify-items: center;
                flex: 1;
                font-size: 36px;
                font-weight: 600;
                color: $DARKGRAY;
                text-align: center;
                width: 100%;
                padding-top: 100px;
            }
            .event-panel {
                display: flex;
                flex-flow: column nowrap;
                width: 300px;
                min-height: 400px;
                border: 2px solid $BLUE1;
                border-radius: 20px;
                margin: 40px;
                .event-panel-title {
                    background-color: $BLUE1;
                    color: white;
                    text-align: center;
                    font-weight: 600;
                    font-size: 24px;
                    border-radius: 16px 16px 0 0;
                }
                .event-panel-image {
                    background-color: $LIGHTGRAY;
                    img {
                        width: 280px;
                        margin: 10px;
                    }
                }
                .event-panel-dates {
                    background-color: $BLUE1;
                    color: white;
                    text-align: center;
                    font-weight: 500;
                    font-size: 24px;
                    margin: 0 0 10px 0;
                }
                .event-panel-button {
                    display: flex;
                    flex-flow: column;
                    padding: 10px;
                    border-bottom: 1px solid $DARKGRAY;
                    align-items: center;
                    p {
                        text-align: center;
                        font-weight: 600;
                    }
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
        .event-registration {
            display: flex;
            padding: 40px;
            .registration-steps {
                display: flex;
                flex-flow: column;
                flex: 1;
                padding-right: 20px;
            }
            .registration-summary {
                display: flex;
                flex-flow: column;
                width: 25%;
                padding: 20px;
                background-color: $LIGHTGRAY;
                .registration-summary-title {
                    text-transform: capitalize;
                    font-size: 24px;
                    font-weight: 500;
                }
                .registration-summary-item, .registration-summary-total {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    padding: 10px 0;
                }
                .registration-summary-total {
                    font-size: 24px;
                    font-weight: 500;
                }
                .registration-summary-buttons {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    //align-self: flex-end;
                }
            }
            .registration-buttons {
                display: flex;
                flex-flow: row;
                padding: 20px 0;
                justify-content: space-between;
            }
        }
        .events-content {
            padding: 10px;
            font-size: 24px;
            border: 1px solid $MIDGRAY;
            .item-tiles {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                float: left;
                .item-tile {
                    display: flex;
                    flex-flow: column nowrap;
                    flex: 1;
                    border-radius: 5px;
                    margin: 5px;
                    padding: 5px;
                    width: 152px;
                    height: 200px;
                    cursor: pointer;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                    .tile-image {
                        width: 150px;
                        height: 150px;
                        background-repeat:no-repeat;
                        background-position:50% 50%;
                        background-size: contain;
                        //border: 1px solid $MIDGRAY;
                    }
                    .tile-name {
                        display: flex;
                        flex-flow: row nowrap;
                        font-size: 14px;
                        text-align:center;
                        font-weight:bold;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 152px;
                    }
                    .tile-price {
                        font-size: 14px;
                        text-align:center;
                    }
                }
            }
        }
    }
    .product-view {
        display: flex;
        flex-flow: column nowrap;
        padding: 20px;
        .product-back-button {
            display: flex;
            flex-flow: row nowrap;
        }
        .product-images {
            display: flex;
            flex-flow: row nowrap;
            width: 50%;
            .media-image {
                display: flex;
                width:400px;
                height:400px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
                border: 1px solid $MIDGRAY;
            }
            .thumbnail-images {
                display: flex;
                flex-flow: row;
                margin-top: 10px;
                .thumbnail-image {
                    width: 50px;
                    height: 50px;
                    background-repeat:no-repeat;
                    background-position:50% 50%;
                    background-size: contain;
                    border: 1px solid $MIDGRAY;
                    cursor: pointer;
                    margin-left: 5px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &.selected {
                        border: 1px solid $CORAL1;
                        cursor: default;
                    }
                }
            }
        
        }
        .product-details {
            display: flex;
            flex-flow: column nowrap;
            width:50%;
            padding-top:20px;
            label {
                font-weight: bold;
            }
            .product-name {
                font-weight: bold;
                font-size: 24px;
            }
            .product-price-and-add-to-cart-button {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                .product-price {
                    border: 1px solid $MIDGRAY;
                    padding: 5px 10px;
                }
                .add-to-cart-button-holder {
                    padding: 5px 10px;
                }
            }
            .inventory-list {
                display: flex;
                flex-flow: row wrap;
                .inventory-item {
                    padding: 5px;
                    border: 1px solid $MIDGRAY;
                    font-size: smaller;
                    width: 30%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;
                    &.disabled {
                        background-color: $MIDGRAY;
                        cursor: default;
                    }
                    &.selected {
                        border: 1px solid $CORAL1;
                        cursor: default;
                    }
                }
            }
            .days-ago {
                font-size: 10px;
                font-style: italic;
            }
            .product-description {
                white-space: break-spaces;
            }
            .owner-buttons {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
        }
    }
    .cart-view {
        display:flex;
        flex-flow: column;
        .back-link {
            background-color: $BLUE3;
            border-top: 1px solid $BLUE1;
            border-right: 1px solid $BLUE1;
            border-bottom: 1px solid $BLUE1;
            color: $BLUE1;
            border-radius: 0 4px 4px 0;
            text-align:center;
            cursor: pointer;
            width: 150px;
            margin: 10px 0;
        }
        .cart-item {
            display: flex;
            flex-flow: row nowrap;
            border-top: 1px solid $MIDGRAY;
            border-bottom: 1px solid $MIDGRAY;
            .cart-image {
                width: 150px;
                height: 150px;
                background-repeat:no-repeat;
                background-position:50% 50%;
                background-size: contain;
                margin: 5px;
            }
            .cart-item-info {
                margin: 5px;
            }
            .cart-item-price {
                display: flex;
                flex-flow: row nowrap;
                flex-grow: 2;
                justify-content: flex-end;
                font-weight: bold;
                margin: 5px;
            }
        }
        .cart-total {
            text-align: right;
            margin: 5px;
        }
    }
    .empty-cart-view {
        display:flex;
        flex-flow: column;
        justify-content: center;
        text-align:center;
        margin-top:200px;
    }
    .cart-total-view {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 10px;
        .cart-total {
            display:flex;
            flex-flow:row nowrap;
            justify-content: flex-end;
            width:80%;
        }
    }
    .shipping-info {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 10px;
        .shipping-item {
            display:flex;
            flex-flow:column nowrap;
            width: 80%;
            padding-bottom: 10px;
        }
    }
    .settings-view {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 10px;
        overflow: auto;
        .setting-item {
            display:flex;
            flex-flow:column nowrap;
            width: 80%;
            padding-bottom: 10px;
        }
        .setting-toggle {
            display: flex;
            flex-flow: row nowrap;
            padding-bottom: 10px;
            justify-content: flex-start;
        }
        .setting-form {
            display:flex;
            flex-flow:column nowrap;
            width: 80%;
            padding-bottom: 10px;
            
            .setting-row {
                display: flex;
                flex-flow: row nowrap;
                flex-grow: 1;
                .setting-column {
                    display: flex;
                    flex-flow: column nowrap;
                    padding-right: 50px;
                }
            }
            input[type=checkbox] {
                align-self: flex-start;
                width: unset;
            }
        }
    }
}
.tab-labels, .member-options {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    border-top: 1px solid $MIDGRAY;
    background-color: $NAVY1;
    padding-left: 10px;
    padding-top: 4px;
    .tab-label {
        display: flex;
        flex-flow: row nowrap;
        padding: 0 15px;
        font-size: 16px;
        border-bottom: 2px solid $NAVY1;
        cursor: pointer;
        background-color: $NAVY1;
        color: white;
        &:hover {
            background-color: $NAVY2;
            border-bottom: 2px solid $CORAL1;
        }
        &.selected {
            color: $CORAL1;
            font-weight: bold;
        }
    }
}
.member-options {
    display: none;
    flex-flow: column;
    padding: 0;
    position: absolute;
    top: 29px;
    right: 0;
    z-index: 100;
    .tab-label {
        font-size: 24px;
        padding: 4px 15px;
        border-bottom: 1px solid $WHITE;
        &:hover {
            background-color: $NAVY1;
            border-bottom: 1px solid $WHITE;
        }
    }
    &.showing {
        display: flex;
    }
}
.app-options {
    display: none;
    flex-flow: column nowrap;
    padding: 0;
    width: 60%;
    position:absolute;
    right: 0;
    z-index: 100;
    background-color: $NAVY1;
    .app-option {
        display: flex;
        flex-flow: row;
        color: $WHITE;
        font-size: 24px;
        padding: 4px;
        border-bottom: 1px solid $WHITE;
        justify-content: flex-end;
        cursor: pointer;
    }
    &.showing {
        display: flex;
    }
}
.tab-content {
    display: flex;
    flex-flow: column;
    overflow: auto;
    position: absolute;
    top: 30px;
    bottom: 0;
    right: 0;
    left: 0;    
}
.agreement-container {
    height: 300px;
    border: 1px solid $MIDGRAY;
    overflow-y:auto;
    padding: 0 10px;
    margin-bottom: 5px;
}
@media screen and (max-width:1000px) {
    .portal {
        .portal-body {
            .body-content {
                .left-side {
                    width: 100%;
                }
                .right-side {
                    display: none;
                }
            }
        }
    }
}
@media (max-width:768px) {
    .portal {
        .portal-header {
            .header-content {
                .content-left {
                    .tenant-icon {
                        display: none;
                    }
                }
                .content-right {
                    
                    .language-switcher {
                        display: none;
                    }
                }
                .mobile-content-right {
                    .mobile-options-menu {

                    }
                }
            }
        }
    }
    .tab-content {
        top: 0;
    }
    .tab-labels {
        display: none;
        flex-flow: column;
        padding: 0;
        width: 70%;
        position: absolute;
        z-index: 100;
        .tab-label {
            font-size: 24px;
            padding: 4px;
            border-bottom: 1px solid $WHITE;
            &:hover {
                background-color: $NAVY1;
                border-bottom: 1px solid $WHITE;
            }
        }
        &.showing {
            display: flex;
        }
    }
    .member-options {
        top: 0;
    }
    .events-container {
        padding: 10px;
        .event-registration {
            flex-flow: column;
            padding: 0px!important;
            //margin: 10px;
            flex-direction: column-reverse;
            .registration-steps {
                width: 100%;
                padding-right: 0;
                margin-top: 20px;
            }
            .registration-summary {
                width: unset!important;
                padding: 10px!important;
            }
        }
        .events-content {
            .item-tiles {
                float: none!important;
                .item-tile {
                    align-items: center;
                    .tile-image {
                        width: 100%;
                    }
                }
            }
            .acitvities-dates {
                display: flex;
                flex-flow: column;
            }
        }
    }
    .product-view.product-view {
        padding: 10px;
        .product-back-button {
            font-size: 24px;
        }
        .product-images {
            margin-top: 20px;
            .media-image.media-image {
                width: calc(100vw - 20px);
                margin-right: 0;
            }
        }
        .product-details.product-details {
            width: 100%;
        }
    }
}
.lang-select {
    position: absolute;
    top: 62px;
    right : 0;
    background: $BLUE3;
    color: $BLUE1;
    width: 200px;
    z-index: 100;
    padding: 10px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            cursor: pointer;
            &:hover {
                filter: brightness(85%);
            }
        }
    }
}

.progress-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .step {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        &::before {
            position: absolute;
            content: "";
            border-bottom: 2px solid $MIDGRAY;
            width: 100%;
            top: 12px;
            left: -50%;
            z-index: 2;
        }
        &::after {
            position: absolute;
            content: "";
            border-bottom: 2px solid $MIDGRAY;
            width: 100%;
            top: 12px;
            left: 50%;
            z-index: 2;
        }
        .step-icon {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: white;
            font-size: 24px;
            margin-bottom: 6px;
        }
        .step-label {
            font-size: 16px;
        }
        &.active {
            .step-icon {
                color: blue;
            }
            .step-label {
                font-weight: 600;
            }
        }
        &:first-child::before {
            content: none;
        }
        &:last-child::after {
            content: none;
        }
    }
}
@media (max-width:768px) {
    .progress-indicator {
        .step {
            .step-label {
                text-align: center;
            }
        }
    }
}
.legend {
    display: flex;
    flex-flow: row;
    justify-content: center;
    .key {
        width: 20px;
        height: 20px;
        border: 1px solid $MIDGRAY;
        &.unavailable {
            background-color: #CCC;
        }
        &.selected {
            border: 1px solid #F00;
        }
    }
    .label {
        margin: 0 10px;
    }
}
.spacesList {
    border: 1px solid;
    height: 100px;
    overflow-x: auto;
    ul {
        list-style: none;
        padding: 0 5px;
        margin: 5px 0;
        li {
            border: 1px solid;
            border-radius: 10px;
            padding: 2px 5px;
            font-size: 14px;
            margin: 2px 0;
            background-color: $MUSTARD2;
            border-color: $CORAL2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
                background-color: $MUSTARD1;
            }
            div {
                &:first-child {
                    font-weight: 600;
                }
            }
        }
    }
}