@import 'style/_base';

.quantity {
    display: flex;
    flex-flow: row nowrap;
    width:100px;
    background-color: $BLUE2;
    height: 20px;
    border: 1px solid $BLUE2;
    border-radius: 10px;
    justify-content: space-between;
    .decrement {
        cursor: pointer;
        padding: 0 10px;
        border-radius: 10px;
        background-color: $CORAL1;
        font-size: 14px;
    }
    .increment {
        cursor: pointer;
        padding: 0 10px;
        border-radius: 10px;
        background-color: $CORAL1;
        font-size: 14px;
    }
}