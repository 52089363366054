@import 'style/_base';

.shiftPickerContainer {
    display: flex;
    flex-flow: column nowrap;
    .shiftList {
        height: 500px;
        overflow-y: scroll;
        border: 1px solid $MIDGRAY;
        border-radius: 5px;
        .scheduleTilesContainer {
            display: flex;
            padding: 10px;
            justify-content: space-between;
            .scheduleTile {
                display: flex;
                flex: 1;
                padding: 4px;
                margin: 2px;
                border: 2px solid $CORAL1;
                border-radius: 5px;
                font-weight: 700;
                max-width: 150px;
                align-items: center;
                text-align: center;
                justify-content: center;
                cursor: pointer;
                &.selected {
                    background-color: $CORAL1;
                    color: white;
                }
            }
        }
        .noShiftsMessage {
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            color: $MIDGRAY;
            padding-top: 50px;
        }
        .selectedSchedule {
            display: flex;
            flex-flow: row;
            .selectedScheduleInstructions {
                margin: 10px;
                padding: 10px;
                max-width: 25%;
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                width: 100%;
                li {
                    border: 1px solid $MIDGRAY;
                    border-radius: 5px;
                    margin: 10px;
                    padding: 10px;
                    display: flex;
                    flex-flow: column nowrap;
                    &.shiftDateRow {
                        background-color: $DARKGRAY;
                        color: white;
                        font-weight: 700;
                        text-align: center;
                    }
                    .shift-information {
                        display: flex;
                        flex-flow: row;
                        justify-content: space-between;
                        align-items: center;
                        .schedule-name {
                            font-weight: bold;
                        }
                    }
                    
                    &.selected {
                        background-color:$LIGHTGRAY;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media (max-width:768px) {
    .shiftPickerContainer {
        .shiftList {
            height: unset;
            .scheduleTilesContainer {
                flex-flow: column;
                .scheduleTile {
                    max-width: unset;
                }
            }
            .selectedSchedule {
                flex-flow: column;
                .selectedScheduleInstructions {
                    max-width: unset;
                }
            }
        }
    }
}