@import 'style/_base';

.join-container {
	display: flex;
	flex-flow: column nowrap;
	width: 400px;
	margin: 50px auto;
	font-family: "Avenir Next W02", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	input[type=text] {
		width: 100%;
	}
}

@media only screen and (max-width:600px) {
    .join-container {
        width: 90%;
        .payment-form {
            width: 100%;
            padding: 0;
        }
    }
}