@import 'style/_base';

.public-panels {
    display: flex;
    overflow: auto;
    ul.public-panel-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        li.public-panel {
            display: flex;
            flex-flow: column nowrap;
            //padding: 20px;
            margin: 20px;
            border: 1px solid #707070;
            border-radius: 10px;
            //text-align:center;
            width: 400px;
            height: 220px;
            background-color: $WHITE;
            div {
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-between;
                &.panel-header {
                    background-color: $CORAL1;
                    color: $WHITE;
                    border-radius: 8px 8px 0 0;
                    justify-content: center;
                    align-items: center;
                    h2, h6 {
                        margin: 5px 0;
                    }
                }
                &.panel-middle {
                    flex-grow: 1;
                    margin: 0 10px;
                    overflow: hidden;
                }
                &.panel-footer {
                    padding: 0 20px 10px;
                    justify-self: flex-end;
                }
            }
        }
    }
}

@media (max-width:768px) {
    .public-panels {
        flex-flow: column nowrap;
    }
}