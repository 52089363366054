@import 'style/_base';

.loading {
    width: 200px;
    height: 100px;
    margin: auto;
    padding-top: 20%;
    font-size: 75px;
    text-align: center;
    color: $NAVY1;
    &.small {
        padding-top: 0;
        font-size: 48px;
    }
}